<template>
    <b-field>
        <div class="container">
            <div class="container"><b><u>{{(content.label === null) ? 'Column' : content.label}}</u></b></div>
            <div class="container"><p style="overflow-wrap: break-word;">{{(content.tooltip === null) ? '' : content.tooltip}}</p></div>
        </div>
    </b-field>
</template>

<script>

    export default {
        name: 'column-header-content',
        props: [
            'content',
        ]
    }
</script>

